import { FC } from 'react'

interface PlayStoreProps { }

const PlayStore: FC<PlayStoreProps> = (): JSX.Element => {
  return (
    <svg width="26" height="29" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.467553 23.4393L11.5038 11.975L0.476811 0.53125C0.182295 0.709397 0 1.12191 0 1.66191V22.3196C0.0039259 22.8596 0.179629 23.242 0.467553 23.4393Z"  />
      <path d="M11.8995 11.6554L15.1869 8.24012L1.79966 0.614545C1.57426 0.478546 1.31819 0.40662 1.05737 0.40625L11.8982 11.654L11.8995 11.6554Z"  />
      <path d="M11.8992 12.2891L1.02405 23.5862C1.29701 23.5909 1.56582 23.5169 1.80064 23.3724L15.2142 15.7263L11.8992 12.2891Z"  />
      <path d="M20.0355 11.0033L15.5806 8.46094L12.2034 11.9695L15.6057 15.4999L20.0342 12.9645C20.9759 12.4259 20.9759 11.5391 20.0355 11.0033Z"  />
    </svg>
  )
}

export default PlayStore